var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-10"},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"md":"3"}},[_c('v-card',{staticStyle:{"height":"100%"}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Total Sessions 2024")]),_c('v-card-text',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h2',_vm._g({staticClass:"text-right"},on),[_vm._v(" "+_vm._s(_vm.data.currentYearSessions)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.data.currentYearSessionsRaw))])])],1),_c('v-card-text',{staticClass:"text-right"},[_c('v-icon',{attrs:{"color":_vm.arrowUpSessions ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.arrowUpSessions ? _vm.mdiArrowUp : _vm.mdiArrowDown)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.data.sessionsDiff))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.data.sessionsDiffRaw))])])],1)],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-card',{staticStyle:{"height":"100%"}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Total Page Views 2024")]),_c('v-card-text',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h2',_vm._g({staticClass:"text-right"},on),[_vm._v(" "+_vm._s(_vm.data.currentYearPageViews)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.data.currentYearPageViewsRaw))])])],1),_c('v-card-text',{staticClass:"text-right"},[_c('v-icon',{attrs:{"color":_vm.arrowUpPageViews ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.arrowUpPageViews ? _vm.mdiArrowUp : _vm.mdiArrowDown)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.data.pageViewsDiff))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.data.pageViewsDiffRaw))])])],1)],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-card',{staticStyle:{"height":"100%"}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Nombre de Sites")]),_c('v-card-text',[_c('h2',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.data.sitesCount))])])],1)],1),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }