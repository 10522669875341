<template>
  <div class="mb-10">
    <v-row>
      <v-spacer></v-spacer>
      <v-col md="3">
        <v-card style="height: 100%">
          <v-card-title class="text-h6">Total Sessions 2024</v-card-title>
          <v-card-text>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <h2 class="text-right" v-on="on">
                  {{ data.currentYearSessions }}
                </h2>
              </template>
              <span>{{ data.currentYearSessionsRaw }}</span>
            </v-tooltip>
          </v-card-text>
          <v-card-text class="text-right">
            <v-icon :color="arrowUpSessions ? 'success' : 'error'">
              {{ arrowUpSessions ? mdiArrowUp : mdiArrowDown }}
            </v-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ data.sessionsDiff }}</span>
              </template>
              <span>{{ data.sessionsDiffRaw }}</span>
            </v-tooltip>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-card style="height: 100%">
          <v-card-title class="text-h6">Total Page Views 2024</v-card-title>
          <v-card-text>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <h2 class="text-right" v-on="on">
                  {{ data.currentYearPageViews }}
                </h2>
              </template>
              <span>{{ data.currentYearPageViewsRaw }}</span>
            </v-tooltip>
          </v-card-text>
          <v-card-text class="text-right">
            <v-icon :color="arrowUpPageViews ? 'success' : 'error'">
              {{ arrowUpPageViews ? mdiArrowUp : mdiArrowDown }}
            </v-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ data.pageViewsDiff }}</span>
              </template>
              <span>{{ data.pageViewsDiffRaw }}</span>
            </v-tooltip>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-card style="height: 100%">
          <v-card-title class="text-h6">Nombre de Sites</v-card-title>
          <v-card-text>
            <h2 class="text-right">{{ data.sitesCount }}</h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
import { mdiArrowUp, mdiArrowDown } from "@mdi/js";
import { formatNumberCompact } from "@/utils/formatting";
import { formatNumber } from "@/utils/formatting";

export default {
  name: "RWMDataHeader",
  components: {},
  data() {
    return {
      data: {
        currentYearSessions: 0,
        currentYearSessionsRaw: 0,
        currentYearPageViews: 0,
        currentYearPageViewsRaw: 0,
        sessionsDiff: 0,
        sessionsDiffRaw: 0,
        pageViewsDiff: 0,
        pageViewsDiffRaw: 0,
        sitesCount: 0,
      },
      arrowUpSessions: true,
      arrowUpPageViews: true,
      mdiArrowUp,
      mdiArrowDown,
    };
  },
  computed: {
    rwmData() {
      return this.$store.getters["general/getRwmData"];
    },
  },
  watch: {
    rwmData: {
      handler() {
        this.data.currentYearSessions = formatNumberCompact(
          this.rwmData.summary.currentYear.sessions
        );
        this.data.currentYearSessionsRaw = formatNumber(
          this.rwmData.summary.currentYear.sessions
        );
        this.data.currentYearPageViews = formatNumberCompact(
          this.rwmData.summary.currentYear.pageViews
        );
        this.data.currentYearPageViewsRaw = formatNumber(
          this.rwmData.summary.currentYear.pageViews
        );
        this.arrowUpSessions =
          this.rwmData.summary.currentYear.sessions >=
          this.rwmData.summary.previousYear.sessions;
        this.arrowUpPageViews =
          this.rwmData.summary.currentYear.pageViews >=
          this.rwmData.summary.previousYear.pageViews;
        this.data.sessionsDiff = formatNumberCompact(
          this.rwmData.summary.currentYear.sessions -
            this.rwmData.summary.previousYear.sessions
        );
        this.data.sessionsDiffRaw = formatNumber(
          this.rwmData.summary.currentYear.sessions -
            this.rwmData.summary.previousYear.sessions
        );
        this.data.pageViewsDiff = formatNumberCompact(
          this.rwmData.summary.currentYear.pageViews -
            this.rwmData.summary.previousYear.pageViews
        );
        this.data.pageViewsDiffRaw = formatNumber(
          this.rwmData.summary.currentYear.pageViews -
            this.rwmData.summary.previousYear.pageViews
        );
        this.data.sitesCount = this.rwmData.summary.sitesCount;
      },
      deep: true,
    },
  },
};
</script>
