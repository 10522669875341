<template>
  <v-card class="mb-10">
    <v-card-title class="text-h5">{{ title }}</v-card-title>
    <v-card-text>
      <vue-apex-charts
        type="bar"
        :options="chartOptions"
        :series="series"
        height="450px"
      ></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { formatNumberCompact, formatNumber } from "@/utils/formatting";
export default {
  name: "MonthlyComparison",
  components: {
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    dataLabel: {
      type: String,
      required: true,
    },
  },
  methods: {},
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => formatNumberCompact(val, 2),
          },
        },
        tooltip: {
          y: {
            formatter: (val) => formatNumber(val),
          },
        },
      },
    };
  },
  computed: {
    rwmData() {
      return this.$store.getters["general/getRwmData"];
    },
  },
  watch: {
    rwmData: {
      handler() {
        this.series = [
          {
            data: this.rwmData[`${this.dataLabel}`].data,
          }
        ];
        // this.chartOptions.xaxis.categories = this.rwmData[`${this.dataLabel}`].categories;
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: this.rwmData[`${this.dataLabel}`].categories,
          },
        };
      },
      deep: true,
    },
  },
};
</script>
